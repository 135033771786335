import { IntegrationSource } from "../VendorCache/vendorCacheTypes";

export class ActivitiesOrder {
    id: number | null;
    activity_id: number;
    order_id: number;
    dependency: "start" | "finish";
    _destroy: boolean;

    static fromAPIObject(obj: any): ActivitiesOrder {
        const activitiesOrder = new ActivitiesOrder();

        activitiesOrder.id = obj.id || null;
        activitiesOrder.activity_id = obj.activity_id;
        activitiesOrder.order_id = obj.order_id;
        activitiesOrder.dependency = obj.dependency;
        activitiesOrder._destroy = obj._destroy || false;

        return activitiesOrder;
    }

    toAPIObject(): any {
        return {
            id: this.id,
            activity_id: this.activity_id,
            order_id: this.order_id,
            dependency: this.dependency,
            _destroy: this._destroy,
        };
    }
}

export class Activity {
    id: number;
    project_id: number;
    activity_id: string;
    created_at: Date;

    // Commmon columns for all activities
    wbs: string;
    name: string;
    start: Date | null;
    finish: Date | null;
    vendor_unique_id: string;
    integration_source: IntegrationSource;

    static fromAPIObject(obj: any): Activity {
        const activity = new Activity();

        activity.id = obj.id;
        activity.project_id = obj.project_id;
        activity.created_at = new Date(obj.created_at);

        activity.wbs = obj.wbs;
        activity.name = obj.name;
        activity.start = obj.start ? new Date(obj.start) : null;
        activity.finish = obj.finish ? new Date(obj.finish) : null;
        activity.vendor_unique_id = obj.vendor_unique_id;
        activity.integration_source = obj.vendor;

        return activity;
    }

    toAPIObject(): any {
        return {
            id: this.id,
            project_id: this.project_id,

            wbs: this.wbs,
            name: this.name,
            start: this.start,
            finish: this.finish,
            vendor_unique_id: this.vendor_unique_id,
        };
    }
}
